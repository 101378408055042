// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-rich-text-page-js": () => import("/opt/build/repo/src/templates/rich-text-page.js" /* webpackChunkName: "component---src-templates-rich-text-page-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-project-js": () => import("/opt/build/repo/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-gallery-js": () => import("/opt/build/repo/src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

